import { MutationTree } from "vuex";

import { State } from "./state";
import { MutationTypes } from "./mutation.types";
import { ISubscription } from "@/interfaces/subscription/ISubscription";

export type Mutations<S = State> = {
  [MutationTypes.SET_SUBSCRIPTIONS](
    state: S,
    payload: Array<ISubscription>
  ): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_SUBSCRIPTIONS](
    state: State,
    payload: Array<ISubscription>
  ) {
    state.subscriptions = [];
    payload.forEach((subscription) => {
      state.subscriptions.push({
        id: subscription.id,
        text: subscription.text,
        value: subscription.value,
        friendly_translations: subscription.friendly_translations,
      });
    });
  },
};
