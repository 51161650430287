import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import RegisterView from "@/views/RegisterView.vue";
import LoginView from "@/views/LoginView.vue";
import DashboardView from "@/views/employee/dashboard/DashboardView.vue";
import ForgotPasswordView from "@/views/ForgotPasswordView.vue";
import SalesInviteView from "@/views/SalesInviteView.vue";
import EmailVerificationView from "@/views/EmailVerificationView.vue";
import EditorThreatArchiveView from "@/views/editor/threats/Edit/ArchiveView.vue";
import EditorThreatSingleView from "@/views/editor/threats/Edit/SingleView.vue";
import EditorTemplateSingleView from "@/views/editor/threats/View/SingleView.vue";
import SettingsView from "@/views/SettingsView.vue";
import analysisQuestionSingleEdit from "@/views/editor/analysisQuestion/Edit/SingleEdit.vue";
import analysisQuestionCreateView from "@/views/editor/analysisQuestion/Create/CreateView.vue";
import recommendationSingleEdit from "@/views/editor/recommendation/SingleEdit.vue";
import industriesArchiveView from "@/views/admin/Industries/ArchiveView.vue";
import companiesArchiveView from "@/views/editor/companies/ArchiveView.vue";
import consultantArchiveView from "@/views/admin/consultants/ArchiveView.vue";
import consultantSingleView from "@/views/admin/consultants/SingleView.vue";
import employeeThreatArchiveView from "@/views/employee/threat/ArchiveView.vue";
import employeeThreatSingleView from "@/views/employee/threat/SingleView.vue";
import companiesSingleView from "@/views/editor/companies/SingleView.vue";
import authService from "@/services/authService";
import { store } from "@/store";
import { IGetUserResponse } from "@/interfaces/auth/IGetUserResponse";
import { ActionTypes as AuthActionTypes } from "@/store/auth/action.types";
import axios from "axios";
import { EnumRoles } from "@/enums/EnumRoles";
import { EnumModes as ESettingsModes } from "@/enums/settingsView/EnumModes";
import { IUser } from "@/interfaces/IUser";
import NotFoundView from "@/views/NotFoundView.vue";
import ThreatCreateView from "@/views/editor/threats/Create/CreateView.vue";
import { EnumModes as EThreatModes } from "@/enums/ThreatView/EnumModes";
import { EnumModes as ERecommendationModes } from "@/enums/recommendationView/EnumModes";
import { EnumModes as ERegisterViewModes } from "@/enums/registerView/EnumModes";
import ThreatTemplateView from "@/views/editor/threats/View/ArchiveView.vue";
import AnalysisQuestionSingleView from "@/views/editor/analysisQuestion/View/SingleView.vue";
import RecommendationSingleView from "@/views/editor/recommendation/SingleView.vue";
import riskAnalysisQuestionSingleView from "@/views/employee/question/SingleView.vue";
import employeeRecommendationArchiveView from "@/views/employee/recommendation/ArchiveView.vue";
import InviteView from "@/views/sales/InviteView.vue";
import ResetPasswordView from "@/views/ResetPasswordView.vue";

/**
 * Meta definitions :
 * RequiresAuth : only requires to be logged in but not verified
 * RequiresVerified : Requires to be logged in and verified user
 * RequiredRoles : Requires the user role to view a specific page. This works alongside requries verified. Means, for requires role to work the meta must have requiresVerified to be true
 * RequiresGuest : Must not log in
 */
const routes: Array<RouteRecordRaw> = [
  /**
   * General Pages
   **/
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      requiresGuest: true,
    },
  },
  {
    path: "/register",
    name: "register",
    component: RegisterView,
    meta: {
      requiresGuest: true,
    },
  },
  {
    path: "/consultant/register",
    name: "registerConsultant",
    component: RegisterView,
    props: {
      mode: ERegisterViewModes.consultant,
    },
    meta: {
      requiresGuest: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: {
      requiresGuest: true,
    },
  },
  {
    path: "/introduction",
    name: "introduction",
    component: SettingsView,
    props: { mode: ESettingsModes.INTRODUCTION },
    meta: {
      requiresVerified: true,
    },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ForgotPasswordView,
    meta: {
      requiresGuest: true,
    },
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: ResetPasswordView,
    meta: {
      requiresGuest: true,
    },
  },
  {
    path: "/verify-email",
    name: "email-verification",
    component: EmailVerificationView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings",
    name: "settings",
    component: SettingsView,
    meta: {
      requiresIntroduced: true,
      requiresAuth: true,
    },
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  /**
   * Sales person
   */
  {
    path: "/sales-view",
    name: "sales-view",
    component: SalesInviteView,
    meta: {
      requiresVerified: true,
      RequiredRoles: [EnumRoles.SALES],
    },
  },
  /**
   * Admin Specific Pages
   */
  {
    path: "/industries",
    name: "admin.industries.archive",
    component: industriesArchiveView,
    meta: {
      requiresVerified: true,
      RequiredRoles: [EnumRoles.ADMIN],
      requiresIntroduced: true,
    },
  },
  {
    path: "/consultants",
    name: "admin.consultants.archive",
    component: consultantArchiveView,
    meta: {
      requiresVerified: true,
      requiresIntroduced: true,
      RequiredRoles: [EnumRoles.ADMIN],
    },
  },
  {
    path: "/consultant/:consultant(\\d+)",
    name: "admin.consultant.single",
    component: consultantSingleView,
    meta: {
      requiresVerified: true,
      requiresIntroduced: true,
      RequiredRoles: [EnumRoles.ADMIN],
    },
  },
  /**
   * Editor Pages
   */
  {
    path: "/companies",
    name: "companies.archive",
    component: companiesArchiveView,
    meta: {
      requiresVerified: true,
      requiresIntroduced: true,
      RequiredRoles: [EnumRoles.ADMIN, EnumRoles.CONSULTANT],
    },
  },
  {
    path: "/company/:company(\\d+)",
    name: "companies.single",
    component: companiesSingleView,
    meta: {
      requiresVerified: true,
      requiresIntroduced: true,
      RequiredRoles: [EnumRoles.ADMIN, EnumRoles.CONSULTANT],
    },
  },
  {
    path: "/threat/create",
    name: "editor.threats.create",
    component: ThreatCreateView,
    meta: {
      requiresVerified: true,
      requiresIntroduced: true,
      RequiredRoles: [EnumRoles.ADMIN, EnumRoles.CONSULTANT],
    },
  },
  {
    path: "/threats",
    name: "editor.threats.archive",
    component: EditorThreatArchiveView,
    meta: {
      requiresVerified: true,
      requiresIntroduced: true,
      RequiredRoles: [EnumRoles.ADMIN, EnumRoles.CONSULTANT],
    },
  },
  {
    path: "/threat/:threat(\\d+)",
    name: "editor.threats.single",
    component: EditorThreatSingleView,
    meta: {
      requiresVerified: true,
      requiresIntroduced: true,
      RequiredRoles: [EnumRoles.ADMIN, EnumRoles.CONSULTANT],
    },
  },
  {
    path: "/threat/:threat(\\d+)/analysis-question/create",
    name: "editor.analysis_question.create",
    component: analysisQuestionCreateView,
    meta: {
      requiresVerified: true,
      requiresIntroduced: true,
      RequiredRoles: [EnumRoles.ADMIN, EnumRoles.CONSULTANT],
    },
  },
  {
    path: "/threat/:threat(\\d+)/analysis-question/:analysis_question(\\d+)",
    name: "editor.analysis_question.single.edit",
    component: analysisQuestionSingleEdit,
    meta: {
      requiresVerified: true,
      requiresIntroduced: true,
      RequiredRoles: [EnumRoles.ADMIN, EnumRoles.CONSULTANT],
    },
  },
  {
    path: "/threat/:threat(\\d+)/recommendation/create",
    name: "editor.recommendation.create",
    component: recommendationSingleEdit,
    props: { mode: ERecommendationModes.CREATE },
    meta: {
      requiresVerified: true,
      requiresIntroduced: true,
      RequiredRoles: [EnumRoles.ADMIN, EnumRoles.CONSULTANT],
    },
  },
  {
    path: "/threat/:threat(\\d+)/recommendation/:recommendation(\\d+)",
    name: "editor.recommendation.single.edit",
    component: recommendationSingleEdit,
    props: { mode: ERecommendationModes.UPDATE },
    meta: {
      requiresVerified: true,
      requiresIntroduced: true,
      RequiredRoles: [EnumRoles.ADMIN, EnumRoles.CONSULTANT],
    },
  },
  /**
   * Consultant Pages
   **/
  {
    path: "/template",
    name: "consultant.threats.template",
    component: ThreatTemplateView,
    meta: {
      requiresVerified: true,
      requiresIntroduced: true,
      RequiredRoles: [EnumRoles.CONSULTANT],
    },
  },
  {
    path: "/template/:threat(\\d+)",
    name: "consultant.template.single",
    component: EditorTemplateSingleView,
    meta: {
      requiresVerified: true,
      requiresIntroduced: true,
      RequiredRoles: [EnumRoles.CONSULTANT],
    },
  },
  {
    path: "/template/:threat(\\d+)/analysis-question/:analysis_question(\\d+)",
    name: "consultant.template.analysisQuestion.single",
    component: AnalysisQuestionSingleView,
    meta: {
      requiresVerified: true,
      requiresIntroduced: true,
      RequiredRoles: [EnumRoles.CONSULTANT],
    },
  },
  {
    path: "/template/:threat(\\d+)/recommendation/:recommendation(\\d+)",
    name: "consultant.template.recommendation.single",
    component: RecommendationSingleView,
    meta: {
      requiresVerified: true,
      requiresIntroduced: true,
      RequiredRoles: [EnumRoles.CONSULTANT],
    },
  },
  /**
   * Employee Specific Pages
   */
  {
    path: "/company/dashboard",
    name: "company.dashboard",
    component: DashboardView,
    meta: {
      requiresVerified: true,
      requiresIntroduced: true,
      RequiredRoles: [EnumRoles.EMPLOYEE],
    },
  },
  {
    path: "/company/threats",
    name: "employee.threat.archive",
    component: employeeThreatArchiveView,
    meta: {
      requiresVerified: true,
      requiresIntroduced: true,
      RequiredRoles: [EnumRoles.EMPLOYEE],
    },
  },
  {
    path: "/company/threat/:threat(\\d+)",
    name: "employee.threat.single",
    component: employeeThreatSingleView,
    meta: {
      requiresVerified: true,
      requiresIntroduced: true,
      RequiredRoles: [EnumRoles.EMPLOYEE],
    },
  },
  {
    path: "/company/threat/:threat/question/:analysis_question(\\d+)",
    name: "employee.threat.question",
    component: riskAnalysisQuestionSingleView,
    meta: {
      requiresVerified: true,
      requiresIntroduced: true,
      RequiredRoles: [EnumRoles.EMPLOYEE],
    },
  },
  {
    path: "/company/recommendations",
    name: "employee.recommendation.archive",
    component: employeeRecommendationArchiveView,
    meta: {
      requiresVerified: true,
      requiresIntroduced: true,
      RequiredRoles: [EnumRoles.EMPLOYEE],
    },
  },
  /**
   * sales specific pages
   */
  {
    path: "/invite",
    name: "sales.invite",
    component: InviteView,
    meta: {
      requiresVerified: true,
      requiresIntroduced: true,
      RequiredRoles: [EnumRoles.SALES],
    },
  },
  /**
   * 404 page
   */
  { path: "/404", component: NotFoundView },
  { path: "/:catchAll(.*)", redirect: "/404" },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to) => {
  // instead of having to check every route record with
  // to.matched.some(record => record.meta.requiresAuth)
  if (
    (to.meta.requiresAuth && !authService.isLoggedIn()) ||
    (to.meta.requiresVerified && !authService.isLoggedIn()) ||
    (to.meta.requiresIntroduced && !authService.isLoggedIn())
  ) {
    // this route requires auth, check if logged in
    // is not, redirect to login page.
    return {
      path: "/login",
      // save the location we were at to come back later
      // query: { redirect: to.fullPath },
    };
  }
  if (to.meta.requiresGuest && authService.isLoggedIn()) {
    const user = await authService.getUser();
    return { path: user.roles[0].dashboard_url.url };
  }

  if (to.meta.requiresVerified && authService.isLoggedIn()) {
    // this route requires auth, check if logged in
    // is not, redirect to login page.
    const user = await authService.getUser();
    if (!user.is_verified) {
      return { path: "/verify-email" };
    }
    if (
      to.meta.RequiredRoles &&
      Array.isArray(to.meta.RequiredRoles) &&
      !to.meta.RequiredRoles.includes(user.roles[0].name)
    ) {
      return { path: user.roles[0].dashboard_url.url };
    }
  }

  if (to.meta.requiresIntroduced && authService.isLoggedIn()) {
    // this route requires auth, check if logged in
    // is not, redirect to login page.
    const user = await authService.getUser();
    if (!user.introduced) {
      return { path: "/introduction" };
    }
  }
});

// router.beforeEach((to, from) => {
//
// });

export default router;
