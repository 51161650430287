import { ActionTree, ActionContext } from "vuex";
import { RootState, store } from "@/store";
import { state, State } from "./state";
import { Mutations } from "./mutations";
import { ActionTypes } from "./action.types";
import axios from "axios";
import { ISuccessResponse as IGetCompanySuccessResponse } from "@/interfaces/company/IGetCompanyResponse";
import { MutationTypes } from "@/store/company/mutation.types";
import { ICompany } from "@/interfaces/company/ICompany";
import {
  IErrorResponse as ISaveCompanyErrorResponse,
  ISuccessResponse as ISaveCompanySuccessResponse,
} from "@/interfaces/company/ISaveCompanyResponse";
import { IProfileForm } from "@/interfaces/settingsView/IProfileForm";
import { IAnalysisQuestion } from "@/interfaces/analysisQuestion/IAnalysisQuestion";
import { IAnalysisStatus } from "@/interfaces/riskAnalysis/IAnalysisStatus";
import { IRecommendation } from "@/interfaces/recommendation/IRecommendation";
import { ICompanyAnalysisRecommendation } from "@/interfaces/riskAnalysis/ICompanyAnalysisRecommendation";
import { IAnalysisQuestionForm } from "@/interfaces/analysisQuestion/IAnalysisQuestionForm";
import dataService from "@/services/dataService";
import { ISaveQuestionSuccessResponse } from "@/interfaces/analysisQuestion/ISaveQuestionResponse";
import { IProgress } from "@/interfaces/company/IProgress";
import { IScore } from "@/interfaces/company/dashboard/IScore";
import { IQuestionAnswers } from "@/interfaces/threat/IQuestionAnswers";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [ActionTypes.SYNC_COMPANY]({
    commit,
  }: AugmentedActionContext): Promise<IGetCompanySuccessResponse>;

  [ActionTypes.UPDATE_COMPANY](
    { commit }: AugmentedActionContext,
    payload: IProfileForm //profile form contains company
  ): Promise<ISaveCompanySuccessResponse | ISaveCompanyErrorResponse>;

  [ActionTypes.START_RISK_ANALYSIS](
    { commit }: AugmentedActionContext,
    payload: { threatID: number; companyID: number }
  ): Promise<Array<IAnalysisQuestion> | false>;

  [ActionTypes.STORE_ANALYSIS](
    { commit }: AugmentedActionContext,
    payload: {
      threatID: number;
      companyID: number;
      question: number;
      answers: number[];
    }
  ): Promise<IAnalysisStatus | false>;

  [ActionTypes.ANALYSIS_STATUS](
    { commit }: AugmentedActionContext,
    payload: {
      threatID: number;
      companyID: number;
    }
  ): Promise<IAnalysisStatus | false>;
  [ActionTypes.GENERATE_RECOMMENDATIONS](
    { commit }: AugmentedActionContext,
    payload: {
      threatID: number;
      companyID: number;
    }
  ): Promise<Array<ICompanyAnalysisRecommendation> | false>;
  [ActionTypes.SYNC_PROGRESS](
    { commit }: AugmentedActionContext,
    companyID: number
  ): Promise<IProgress | false>;
  [ActionTypes.GET_RECOMMENDATIONS_PER_THREAT](
    { commit }: AugmentedActionContext,
    payload: {
      threatID: number;
      companyID: number;
    }
  ): Promise<Array<ICompanyAnalysisRecommendation> | false>;

  [ActionTypes.GET_QUESTION_ANSWERS_PER_THREAT](
    { commit }: AugmentedActionContext,
    payload: {
      threatID: number;
      companyID: number;
    }
  ): Promise<Array<IQuestionAnswers> | false>;
  [ActionTypes.GET_RECOMMENDATIONS](
    { commit }: AugmentedActionContext,
    companyID: number
  ): Promise<Array<ICompanyAnalysisRecommendation> | false>;
  [ActionTypes.RESTART_ANALYSIS](
    { commit }: AugmentedActionContext,
    payload: {
      threatID: number;
      companyID: number;
    }
  ): Promise<Array<IAnalysisQuestion> | false>;
  [ActionTypes.UPDATE_RECOMMENDATION](
    { commit }: AugmentedActionContext,
    payload: {
      recommendationID: number;
      status: string;
    }
  ): Promise<ICompanyAnalysisRecommendation | false>;

  [ActionTypes.GET_CONSULTANT]({
    commit,
  }: AugmentedActionContext): Promise<Array<ICompany> | false>;

  [ActionTypes.GET_PERIODIC_SCORE](
    { commit }: AugmentedActionContext,
    payload: { companyID: number; period: string }
  ): Promise<IScore | false>;

  [ActionTypes.GET_COMPANIES]({
    commit,
  }: AugmentedActionContext): Promise<Array<ICompany> | false>;
  [ActionTypes.GET_COMPANY_SETTINGS](
    { commit }: AugmentedActionContext,
    companyID: number
  ): Promise<ICompany | false>;
  [ActionTypes.UPDATE_COMPANY_SETTINGS](
    { commit }: AugmentedActionContext,
    payload: {
      subscription_id: number;
      company_id: number;
    }
  ): Promise<boolean>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.UPDATE_RECOMMENDATION](
    { commit },
    payload: {
      recommendationID: number;
      status: string;
    }
  ) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `/company/recommendation/${payload.recommendationID}?_method=PUT`,
          { status: payload.status }
        )
        .then((response) => {
          resolve(response.data.data as ICompanyAnalysisRecommendation);
        })
        .catch((error) => {
          resolve(false);
        });
    });
  },

  async [ActionTypes.ANALYSIS_STATUS](
    { commit },
    payload: {
      threatID: number;
      companyID: number;
    }
  ) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/company/${payload.companyID}/analysis/${payload.threatID}`)
        .then((response) => {
          resolve(response.data.data as IAnalysisStatus);
        })
        .catch((error) => {
          console.error(error.response.data);
          resolve(false);
        });
    });
  },
  async [ActionTypes.SYNC_PROGRESS]({ commit }, companyID: number) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/company/${companyID}/progress`)
        .then((response) => {
          const progress: IProgress = response.data.data;
          commit(MutationTypes.SET_PROGRESS, progress);
          resolve(progress);
        })
        .catch((error) => {
          console.error(error.response.data);
          resolve(false);
        });
    });
  },
  async [ActionTypes.GET_PERIODIC_SCORE](
    { commit },
    payload: { companyID: number; period: string }
  ) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/company/${payload.companyID}/score/${payload.period}`)
        .then((response) => {
          resolve(response.data.data as IScore);
        })
        .catch((error) => {
          console.error(error.response.data);
          resolve(false);
        });
    });
  },

  async [ActionTypes.SYNC_COMPANY]({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get("/user-companies").then((response) => {
        store.commit(
          MutationTypes.SET_COMPANIES,
          response.data.data.companies as Array<ICompany>
        );
        resolve(response.data as IGetCompanySuccessResponse);
      });
    });
  },
  async [ActionTypes.START_RISK_ANALYSIS](
    { commit },
    payload: { threatID: number; companyID: number }
  ) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/company/${payload.companyID}/startRiskAnalysis/${payload.threatID}`
        )
        .then((response) => {
          resolve(response.data.data as Array<IAnalysisQuestion>);
        })
        .catch((error) => {
          console.error(error.response.data);
          resolve(false);
        });
    });
  },
  async [ActionTypes.RESTART_ANALYSIS](
    { commit },
    payload: { threatID: number; companyID: number }
  ) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/company/${payload.companyID}/restartRiskAnalysis/${payload.threatID}`
        )
        .then((response) => {
          resolve(response.data.data as Array<IAnalysisQuestion>);
        })
        .catch((error) => {
          console.error(error.response.data);
          resolve(false);
        });
    });
  },
  async [ActionTypes.STORE_ANALYSIS](
    { commit },
    payload: {
      threatID: number;
      companyID: number;
      question: number;
      answers: number[];
    }
  ) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/company/${payload.companyID}/analysis/${payload.threatID}`, {
          question: payload.question,
          answers: payload.answers,
        })
        .then((response) => {
          resolve(response.data.data as IAnalysisStatus);
        })
        .catch((error) => {
          console.error(error.response.data);
          resolve(false);
        });
    });
  },
  async [ActionTypes.GENERATE_RECOMMENDATIONS](
    { commit },
    payload: {
      threatID: number;
      companyID: number;
    }
  ) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `/company/${payload.companyID}/threat/${payload.threatID}/recommendations`
        )
        .then((response) => {
          resolve(response.data.data as Array<ICompanyAnalysisRecommendation>);
        })
        .catch((error) => {
          console.error(error.response.data);
          resolve(false);
        });
    });
  },
  async [ActionTypes.GET_RECOMMENDATIONS_PER_THREAT](
    { commit },
    payload: {
      threatID: number;
      companyID: number;
    }
  ) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/company/${payload.companyID}/threat/${payload.threatID}/recommendations`
        )
        .then((response) => {
          resolve(response.data.data as Array<ICompanyAnalysisRecommendation>);
        })
        .catch((error) => {
          console.error(error.response.data);
          resolve(false);
        });
    });
  },
  async [ActionTypes.GET_QUESTION_ANSWERS_PER_THREAT](
    { commit },
    payload: {
      threatID: number;
      companyID: number;
    }
  ) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/company/${payload.companyID}/threat/${payload.threatID}/question-answers`
        )
        .then((response) => {
          resolve(response.data.data as Array<IQuestionAnswers>);
        })
        .catch((error) => {
          console.error(error.response.data);
          resolve(false);
        });
    });
  },
  async [ActionTypes.GET_RECOMMENDATIONS]({ commit }, companyID: number) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/company/${companyID}/recommendations`)
        .then((response) => {
          resolve(response.data.data as Array<ICompanyAnalysisRecommendation>);
        })
        .catch((error) => {
          console.error(error.response.data);
          resolve(false);
        });
    });
  },
  async [ActionTypes.UPDATE_COMPANY]({ commit }, payload: IProfileForm) {
    return new Promise((resolve) => {
      const formData = new FormData();
      formData.append("company_id", payload.company_id.toString());
      formData.append("company_name", payload.company_name.toString());
      formData.append("headquarters", payload.headquarters.toString());
      formData.append("website", payload.website.toString());
      formData.append("remove_logo", payload.remove_logo.toString());
      formData.append("industry_id", payload.industry_id.toString());
      formData.append("size", payload.size.toString());
      if (payload.logo) formData.append("logo", payload.logo);
      axios
        .post("/user-company", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) =>
          resolve(response.data as ISaveCompanySuccessResponse)
        )
        .catch((error) => {
          resolve(error.response.data); //resolve as we've caught the error here, now in view we can do line by line coding
        });
    });
  },

  async [ActionTypes.GET_CONSULTANT]({ commit }) {
    return new Promise((resolve) => {
      axios
        .get("/consultants")
        .then(async (response) => {
          resolve(response.data.data as Array<ICompany>);
        })
        .catch((error) => {
          resolve(false);
        });
    });
  },

  async [ActionTypes.GET_COMPANIES]({ commit }) {
    return new Promise((resolve) => {
      axios
        .get("/companies")
        .then(async (response) => {
          resolve(response.data.data as Array<ICompany>);
        })
        .catch((error) => {
          resolve(false);
        });
    });
  },

  async [ActionTypes.GET_COMPANY_SETTINGS]({ commit }, companyID: number) {
    return new Promise((resolve) => {
      axios
        .get(`/company-settings/${companyID}`)
        .then(async (response) => {
          resolve(response.data.data as ICompany);
        })
        .catch((error) => {
          resolve(false);
        });
    });
  },
  async [ActionTypes.UPDATE_COMPANY_SETTINGS](
    { commit },
    payload: {
      subscription_id: number;
      company_id: number;
    }
  ) {
    return new Promise((resolve) => {
      axios
        .post(`/company-settings/${payload.company_id}?_method=PUT`, payload)
        .then(async (response) => {
          resolve(response.data.data as boolean);
        })
        .catch((error) => {
          resolve(false);
        });
    });
  },
};
