import { ActionTree, ActionContext } from "vuex";
import { RootState, store } from "@/store";
import { State } from "./state";
import { Mutations } from "./mutations";
import { ActionTypes } from "./action.types";
import ILoginForm from "@/interfaces/auth/ILoginForm";
import IRegisterForm from "@/interfaces/auth/IRegisterForm";
import axios from "axios";
import {
  ISuccessResponse as IAuthSuccessResponse,
  IErrorResponse as IAuthErrorResponse,
} from "@/interfaces/auth/IAuthResponse";
import { IGetUserResponse } from "@/interfaces/auth/IGetUserResponse";
import {
  IResendEmailErrorResponse,
  IResendEmailSuccessResponse,
} from "@/interfaces/auth/IResendEmailResponse";
import { IEmailVerificationResponse } from "@/interfaces/auth/IEmailVerificationResponse";
import IEmailVerificationRequest from "@/interfaces/auth/IEmailVerificationRequest";
import { MutationTypes as AuthMutationTypes } from "@/store/auth/mutation.types";
import authService from "@/services/authService";
import { IInvitationForm } from "@/interfaces/invitationControls/IInvitationForm";
import { IInvitation } from "@/interfaces/auth/IInvitation";
import { IInviteErrorResponse } from "@/interfaces/invitationControls/IInviteErrorResponse";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [ActionTypes.LOGIN](
    { commit }: AugmentedActionContext,
    payload: ILoginForm
  ): Promise<IAuthSuccessResponse | IAuthErrorResponse>;

  [ActionTypes.REGISTER](
    { commit }: AugmentedActionContext,
    payload: IRegisterForm
  ): Promise<IAuthSuccessResponse | IAuthErrorResponse>;

  [ActionTypes.REGISTER_INVITED](
    { commit }: AugmentedActionContext,
    payload: { code: string; credentials: IRegisterForm }
  ): Promise<IAuthSuccessResponse | IAuthErrorResponse>;

  [ActionTypes.INVITE_CONSULTANT](
    { commit }: AugmentedActionContext,
    payload: IInvitationForm
  ): Promise<true | IInviteErrorResponse>;

  [ActionTypes.INVITE_SALES](
    { commit }: AugmentedActionContext,
    payload: IInvitationForm
  ): Promise<true | IInviteErrorResponse>;

  [ActionTypes.REGISTER_CONSULTANT](
    { commit }: AugmentedActionContext,
    payload: IRegisterForm
  ): Promise<IAuthSuccessResponse | IAuthErrorResponse>;

  [ActionTypes.ME]({
    commit,
  }: AugmentedActionContext): Promise<IGetUserResponse>;

  [ActionTypes.RESENT_EMAIL_VERIFICATION]({
    commit,
  }: AugmentedActionContext): Promise<
    IResendEmailErrorResponse | IResendEmailSuccessResponse
  >;

  [ActionTypes.IMPERSONATE](
    { commit }: AugmentedActionContext,
    userID: number
  ): Promise<IAuthSuccessResponse | IAuthErrorResponse>;

  [ActionTypes.GET_INVITATION](
    { commit }: AugmentedActionContext,
    refCode: string
  ): Promise<IInvitation | false>;

  [ActionTypes.FORGOT_PASSWORD](
    { commit }: AugmentedActionContext,
    payload: { email: string; consultant_id: string }
  ): Promise<boolean>;

  [ActionTypes.RESET_PASSWORD](
    { commit }: AugmentedActionContext,
    payload: {
      consultant_id: string;
      token: string;
      password: string;
      confirm_password: string;
      email: string;
    }
  ): Promise<boolean>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.LOGIN]({ commit }, payload: ILoginForm) {
    return new Promise((resolve, reject) => {
      axios
        .post("/login", payload)
        .then((response) => resolve(response.data as IAuthSuccessResponse))
        .catch((error) => reject(error.response.data as IAuthErrorResponse));
    });
  },
  async [ActionTypes.IMPERSONATE]({ commit }, userID: number) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/impersonate/${userID}`)
        .then((response) => resolve(response.data as IAuthSuccessResponse))
        .catch((error) => reject(error.response.data as IAuthErrorResponse));
    });
  },
  async [ActionTypes.GET_INVITATION]({ commit }, refCode: string) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/invitation/${refCode}`)
        .then((response) => resolve(response.data.data as IInvitation))
        .catch((error) => {
          console.error(error);
          resolve(false);
        });
    });
  },
  async [ActionTypes.LOGIN]({ commit }, payload: ILoginForm) {
    return new Promise((resolve, reject) => {
      axios
        .post("/login", payload)
        .then((response) => resolve(response.data as IAuthSuccessResponse))
        .catch((error) => reject(error.response.data as IAuthErrorResponse));
    });
  },
  async [ActionTypes.FORGOT_PASSWORD](
    { commit },
    payload: { email: string; consultant_id: string }
  ) {
    return new Promise((resolve, reject) => {
      axios
        .post("/forgot-password", payload)
        .then((response) => resolve(true))
        .catch((error) => {
          console.error(error);
          resolve(false);
        });
    });
  },

  async [ActionTypes.RESET_PASSWORD](
    { commit },
    payload: {
      email: string;
      consultant_id: string;
      token: string;
      password: string;
      confirm_password: string;
    }
  ) {
    return new Promise((resolve, reject) => {
      axios
        .post("/reset-password", payload)
        .then((response) => resolve(response.data.data))
        .catch((error) => {
          console.error(error);
          resolve(false);
        });
    });
  },

  async [ActionTypes.REGISTER]({ commit }, payload: IRegisterForm) {
    return new Promise((resolve, reject) => {
      axios
        .post("/register", payload)
        .then((response) => resolve(response.data as IAuthSuccessResponse))
        .catch((error) => reject(error.response.data as IAuthErrorResponse));
    });
  },
  async [ActionTypes.REGISTER_INVITED](
    { commit },
    payload: { code: string; credentials: IRegisterForm }
  ) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/register/${payload.code}`, payload.credentials)
        .then((response) => resolve(response.data as IAuthSuccessResponse))
        .catch((error) => reject(error.response.data as IAuthErrorResponse));
    });
  },

  async [ActionTypes.INVITE_CONSULTANT]({ commit }, payload: IInvitationForm) {
    return new Promise((resolve, reject) => {
      axios
        .post("/invite", payload)
        .then((response) => resolve(true))
        .catch((error) => resolve(error.response.data as IInviteErrorResponse));
    });
  },

  async [ActionTypes.INVITE_SALES]({ commit }, payload: IInvitationForm) {
    return new Promise((resolve, reject) => {
      axios
        .post("/sales/invite", payload)
        .then((response) => resolve(true))
        .catch((error) => resolve(error.response.data as IInviteErrorResponse));
    });
  },
  async [ActionTypes.REGISTER_CONSULTANT]({ commit }, payload: IRegisterForm) {
    return new Promise((resolve, reject) => {
      axios
        .post("/register-consultant", payload)
        .then((response) => resolve(response.data as IAuthSuccessResponse))
        .catch((error) => reject(error.response.data as IAuthErrorResponse));
    });
  },

  async [ActionTypes.RESENT_EMAIL_VERIFICATION]() {
    return new Promise((resolve, reject) => {
      axios
        .post("/resend-verification-email")
        .then((response) =>
          resolve(response.data as IResendEmailSuccessResponse)
        )
        .catch((error) =>
          reject(error.response.data as IResendEmailErrorResponse)
        );
    });
  },

  async [ActionTypes.VERIFY_EMAIL](
    { commit },
    payload: IEmailVerificationRequest
  ) {
    return new Promise((resolve, reject) => {
      axios
        .post("/verify-email", payload)
        .then((response) =>
          resolve(response.data as IEmailVerificationResponse)
        )
        .catch((error) =>
          reject(error.response.data as IEmailVerificationResponse)
        );
    });
  },

  async [ActionTypes.ME]({ commit }) {
    return new Promise((resolve) => {
      axios
        .get("/me")
        .then(async (response) => {
          await store.commit(AuthMutationTypes.SET_ME, response.data.data.user);
          resolve(response.data as IGetUserResponse);
        })
        .catch((error) => {
          authService.logout(); //logout if "Me" is not able to return
          // resolve(error.response.data);
        });
    });
  },
};
