export enum ActionTypes {
  SYNC_COMPANY = "getUserCompanies",
  UPDATE_COMPANY = "updateUserCompany",
  START_RISK_ANALYSIS = "getFirstAnalysisQuestion",
  STORE_ANALYSIS = "storeAnswersForCompany",
  ANALYSIS_STATUS = "getAnalysisStatusForCompany",
  GENERATE_RECOMMENDATIONS = "generateAnalysisRecommendations",
  GET_RECOMMENDATIONS_PER_THREAT = "getAnalysisRecommendationsForThreat",
  GET_RECOMMENDATIONS = "getAnalysisRecommendationsForCompany",
  RESTART_ANALYSIS = "restartAnalysisForCompany",
  UPDATE_RECOMMENDATION = "updateRecommendationStatusForCompany",
  SYNC_PROGRESS = "syncProgressForCompany",
  GET_CONSULTANT = "getConsultantList",
  GET_COMPANIES = "getCompaniesList",
  GET_PERIODIC_SCORE = "getPeriodicScore",
  GET_COMPANY_SETTINGS = "getCompanySettings",
  UPDATE_COMPANY_SETTINGS = "updateCompanySettings",
  GET_QUESTION_ANSWERS_PER_THREAT = "getQuestionAnswersForCompanyPerThreat",
}
