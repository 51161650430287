import { GetterTree } from "vuex";
import { RootState } from "@/store";
import { State } from "./state";
import { IConsultantSettings } from "@/interfaces/consultant/IConsultantSettings";

export type Getters = {
  getConsultantSettings(state: State): IConsultantSettings | null;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getConsultantSettings: (state) => state.consultant_settings,
};
