import { ActionTree, ActionContext } from "vuex";
import { RootState, store } from "@/store";
import { ActionTypes } from "./action.types";
import axios from "axios";
import { IDesignationResponse } from "@/interfaces/designation/IDesignationResponse";
import { State } from "@/store/designation/state";

export interface Actions {
  [ActionTypes.GET_DESIGNATION](): Promise<IDesignationResponse>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.GET_DESIGNATION]() {
    return new Promise((resolve, reject) => {
      axios
        .get("/designation")
        .then((response) => resolve(response.data as IDesignationResponse));
    });
  },
};
