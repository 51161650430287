import { MutationTree } from "vuex";

import { State } from "./state";
import { MutationTypes } from "./mutation.types";
import { IMenuItem } from "@/interfaces/menu/IMenuItem";

export type Mutations<S = State> = {
  [MutationTypes.SET_MENU](state: S, payload: IMenuItem[][]): void;
  [MutationTypes.SET_LOADING](state: S, payload: boolean): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_MENU](state: State, payload: IMenuItem[][]) {
    state.menus = payload;
  },
  [MutationTypes.SET_LOADING](state: State, payload: boolean) {
    state.isLoading = payload;
  },
};
