import { GetterTree } from "vuex";
import { RootState } from "@/store";
import { State } from "./state";
import { IPriority } from "@/interfaces/priority/IPriority";

export type Getters = {
  getPriorities(state: State): Array<IPriority>;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getPriorities: (state) => state.priorities,
};
