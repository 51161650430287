import { ActionTree, ActionContext } from "vuex";
import { RootState, store } from "@/store";
import { State } from "./state";
import { Mutations } from "./mutations";
import { ActionTypes } from "./action.types";
import axios from "axios";
import { IPriorityResponse } from "@/interfaces/priority/IPriorityResponse";
import { MutationTypes } from "@/store/priority/mutation.types";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [ActionTypes.SYNC_PRIORITIES]({
    commit,
  }: AugmentedActionContext): Promise<IPriorityResponse>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.SYNC_PRIORITIES]() {
    return new Promise((resolve, reject) => {
      axios.get("/priority").then((response) => {
        const priorityResponse = response.data as IPriorityResponse;
        store.commit(MutationTypes.SET_PRIORITIES, priorityResponse.data);
        resolve(priorityResponse);
      });
    });
  },
};
