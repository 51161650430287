import { MutationTree } from "vuex";

import { state, State } from "./state";
import { MutationTypes } from "./mutation.types";
import { ICompany } from "@/interfaces/company/ICompany";
import { IProgress } from "@/interfaces/company/IProgress";

export type Mutations<S = State> = {
  [MutationTypes.SET_COMPANIES](state: S, payload: Array<ICompany>): void;
  [MutationTypes.SET_PROGRESS](state: S, payload: IProgress): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_COMPANIES](state: State, payload: Array<ICompany>) {
    state.companies = [];
    payload.forEach((company) => {
      state.companies.push({
        id: company.id,
        website: company.website,
        company_name: company.company_name,
        headquarters: company.headquarters,
        subscription_id: company.subscription_id,
        subscription: company.subscription,
        pivot: {
          company_id: company.pivot.company_id,
          user_id: company.pivot.user_id,
          is_owner: company.pivot.is_owner,
        },
        logo: company.logo,
        logo_url: company.logo_url,
        industry_id: company.industry_id,
        size: company.size,
      });
    });
    /**
     * for now, we're setting the selected company as 0th index
     * In the future, if we introduce where user may have multiple companies then we'll update it to use selected company as defined by user
     */
    state.selectedCompany = payload[0];
  },
  [MutationTypes.SET_PROGRESS](state: State, payload: IProgress) {
    state.progress.total_points = payload.total_points;
    state.progress.earned_points = payload.earned_points;
  },
};
