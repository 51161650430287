import { ICompany } from "@/interfaces/company/ICompany";
import { IProgress } from "@/interfaces/company/IProgress";

export type State = {
  companies: Array<ICompany>;
  selectedCompany: ICompany;
  progress: IProgress;
};

export const state: State = {
  companies: [],
  selectedCompany: {
    id: null,
    website: null,
    company_name: null,
    headquarters: null,
    pivot: { company_id: null, is_owner: false, user_id: null },
    logo: null,
    logo_url: null,
    industry_id: null,
    size: null,
    subscription_id: null,
    subscription: null,
  },
  progress: {
    earned_points: 0,
    total_points: 0,
  },
};
