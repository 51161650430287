import { MutationTree } from "vuex";

import { State } from "./state";
import { MutationTypes } from "./mutation.types";
import { IConsultantSettings } from "@/interfaces/consultant/IConsultantSettings";

export type Mutations<S = State> = {
  [MutationTypes.SET_CONSULTANT](state: S, payload: IConsultantSettings): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_CONSULTANT](
    state: State,
    consultantSettings: IConsultantSettings
  ) {
    state.consultant_settings = consultantSettings;
  },
};
