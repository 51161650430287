export enum ActionTypes {
  LOGIN = "login",
  REGISTER = "register",
  REGISTER_CONSULTANT = "registerConsultant",
  RESENT_EMAIL_VERIFICATION = "resendEmailVerification",
  VERIFY_EMAIL = "verifyEmail",
  ME = "getAuthenticatedUser",
  IMPERSONATE = "impersonateUser",
  INVITE_CONSULTANT = "inviteEmployeeByConsultant",
  INVITE_SALES = "inviteEmployeeBySales",
  GET_INVITATION = "getInvitation",
  REGISTER_INVITED = "registerInvitedCompany",
  FORGOT_PASSWORD = "forgotPassword",
  RESET_PASSWORD = "resetPassword",
}
