import { GetterTree } from "vuex";
import { RootState } from "@/store";
import { State } from "./state";
import { ICompany } from "@/interfaces/company/ICompany";
import { IProgress } from "@/interfaces/company/IProgress";

export type Getters = {
  getSelectedCompany(state: State): ICompany;
  getCompaniesState(state: State): Array<ICompany>;
  getCompanyProgress(state: State): IProgress;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getSelectedCompany: (state) => state.selectedCompany,
  getCompaniesState: (state) => {
    return state.companies;
  },
  getCompanyProgress: (state) => {
    return state.progress;
  },
};
