import { MutationTree } from "vuex";

import { State } from "./state";
import { MutationTypes } from "./mutation.types";
import { IIndustry } from "@/interfaces/industryView/IIndustry";

export type Mutations<S = State> = {
  [MutationTypes.SET_INDUSTRY](state: S, payload: Array<IIndustry>): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_INDUSTRY](state: State, payload: Array<IIndustry>) {
    state.industries = payload;
  },
};
