import { MutationTree } from "vuex";

import { State } from "./state";
import { MutationTypes } from "./mutation.types";
import { IPriority } from "@/interfaces/priority/IPriority";

export type Mutations<S = State> = {
  [MutationTypes.SET_PRIORITIES](state: S, payload: Array<IPriority>): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_PRIORITIES](state: State, priorities: Array<IPriority>) {
    state.priorities = priorities;
  },
};
