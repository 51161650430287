<template>
  <section class="bg-on-primary dark:bg-gray-900">
    <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
      <div class="mx-auto max-w-screen-sm text-center">
        <h1
          class="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary dark:text-primary-500"
        >
          404
        </h1>
        <p
          class="mb-4 text-3xl tracking-tight font-bold text-secondary md:text-4xl dark:text-white"
        >
          {{ $t("notFoundView.subTitle") }}
        </p>
        <p class="mb-4 text-lg font-light text-tertiary dark:text-gray-400">
          {{ $t("notFoundView.description") }}
        </p>
        <router-link
          to="/"
          class="inline-flex text-white button-primary button dark:focus:ring-primary-900 my-4"
        >
          <i class="mt-1 mr-1 fa fa-arrow-left"></i>
          {{ $t("notFoundView.backButtonText") }}</router-link
        >
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup></script>

<style scoped></style>
