import router from "@/router";
import { IGetUserResponse } from "@/interfaces/auth/IGetUserResponse";
import { store } from "@/store";
import {
  ActionTypes,
  ActionTypes as AuthActionTypes,
} from "@/store/auth/action.types";
import { IUser } from "@/interfaces/IUser";
import { state } from "@/store/auth/state";
import {
  IErrorResponse as IAuthErrorResponse,
  ISuccessResponse as IAuthSuccessResponse,
} from "@/interfaces/auth/IAuthResponse";
import { MutationTypes } from "@/store/auth/mutation.types";
import viewService from "@/services/viewService";
import Swal from "sweetalert2";

export default new (class {
  isLoggedIn() {
    return localStorage.getItem("authToken") !== null;
  }

  setLogin(token: string) {
    localStorage.setItem("authToken", token);
  }

  getToken(): string | null {
    return localStorage.getItem("authToken");
  }

  logout() {
    Object.assign(state, store.getters.getDefaultUserState);
    localStorage.removeItem("impersonateStack");
    localStorage.removeItem("authToken");
    router.push("/login");
  }
  getImpersonateStack(): string[] {
    // Retrieve the stored array from local storage
    return JSON.parse(localStorage.getItem("impersonateStack")!) || [];
  }
  async stopImpersonate() {
    const impersonateStack = this.getImpersonateStack();
    if (impersonateStack.length) {
      this.setLogin(impersonateStack.pop() || "");
      localStorage.setItem(
        "impersonateStack",
        JSON.stringify(impersonateStack)
      );
      const response = await store.dispatch(ActionTypes.ME);
      store.commit(MutationTypes.SET_ME, response.data.user);
      await viewService.setLoggedInBasicView();
      router.push(response.data.user.roles[0].dashboard_url.url);
    }
  }
  impersonate(id: number) {
    store
      .dispatch(AuthActionTypes.IMPERSONATE, id)
      .then(async (response: IAuthSuccessResponse) => {
        /**
         * make impersonate stack as array
         */
        const impersonateStack = this.getImpersonateStack();
        // Push new elements to the array
        impersonateStack.push(this.getToken() || "");
        // Store the updated array back to local storage
        localStorage.setItem(
          "impersonateStack",
          JSON.stringify(impersonateStack)
        );

        await this.setLogin(response.data.token);
        store.commit(MutationTypes.SET_ME, response.data.user);
        await viewService.setLoggedInBasicView();
        router.push(response.data.user.roles[0].dashboard_url.url);
      })
      .catch((error: IAuthErrorResponse) => {
        Swal.fire({
          toast: true,
          showConfirmButton: false,
          position: "top-end",
          icon: "error",
          title: error.message,
          timer: 3000,
        });
      });
  }

  async getUser() {
    /**
     * not every redirection for logged-in user
     */
    let user: IUser = store.getters.getUser;
    if (null == user.id) {
      //only do it if refreshing the page
      const result: IGetUserResponse = await store.dispatch(AuthActionTypes.ME);
      user = result.data.user;
    }
    return user;
  }
})();
