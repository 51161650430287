import { store } from "@/store";
import { ActionTypes as CompanyActionTypes } from "@/store/company/action.types";
import { ActionTypes as MenuActionTypes } from "@/store/menu/action.types";
import { ActionTypes as subscriptionActions } from "@/store/subscription/action.types";
import authService from "@/services/authService";
import { IUser } from "@/interfaces/IUser";
import { EnumRoles } from "@/enums/EnumRoles";
import { ActionTypes as IndustryActions } from "@/store/industry/action.types";
import { IPriorityResponse } from "@/interfaces/priority/IPriorityResponse";
import { ActionTypes as PriorityActionTypes } from "@/store/priority/action.types";

export default new (class {
  /**
   * Basic view to run before Verified or Introduced user
   */
  async setLoggedInBasicView() {
    await Promise.all([
      /**
       * SYNC INDUSTRIES
       */
      store.dispatch(IndustryActions.SYNC_INDUSTRY),
      /**
       * SYNC COMPANY
       */
      store.dispatch(CompanyActionTypes.SYNC_COMPANY),
      /**
       * get set menu
       */
      store.dispatch(MenuActionTypes.GET_MENU),
    ]);
    /**
     * necessary views refreshing specially for editor (admin/consultant/sales)
     */
    const user: IUser = await authService.getUser();
    if (
      user.roles[0].name == EnumRoles.ADMIN ||
      user.roles[0].name == EnumRoles.CONSULTANT ||
      user.roles[0].name == EnumRoles.SALES
    ) {
      await Promise.all([
        /**
         * getting priorities to be used by industries
         */
        store.dispatch(PriorityActionTypes.SYNC_PRIORITIES),
        /**
         * subscriptions
         */
        store.dispatch(subscriptionActions.SYNC_SUBSCRIPTIONS),
      ]);
    }
    if (user.roles[0].name == EnumRoles.EMPLOYEE) {
      /**
       * must be done post company sync
       */
      store.dispatch(
        CompanyActionTypes.SYNC_PROGRESS,
        store.getters.getSelectedCompany.id
      );
    }
  }
})();
