export enum ActionTypes {
  CREATE_THREAT = "createThreat",
  UPDATE_THREAT = "updateThreat",
  GET_THREAT = "getThreat",
  DELETE_THREAT = "deleteThreat",
  GET_THREATS = "getThreats",
  GET_QUESTIONS_ANSWERS = "getQuestionAnswers",
  ACTIVATE = "activateThreat",
  DEACTIVATE = "deactivateThreat",
  DUPLICATE = "duplicateThreat",
  GET_TEMPLATE = "getTemplate",
  /**
   * employee actions
   */
  GET_COMPANY_THREATS = "getCompanyThreats",
  GET_COMPANY_THREAT = "getCompanyThreat",
}
