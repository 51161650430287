import { ActionTree, ActionContext } from "vuex";
import { RootState, store } from "@/store";
import { State } from "./state";
import { Mutations } from "./mutations";
import { ActionTypes } from "./action.types";
import axios from "axios";
import { IMenuResponse } from "@/interfaces/menu/IMenuResponse";
import { MutationTypes } from "@/store/menu/mutation.types";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [ActionTypes.GET_MENU]({
    commit,
  }: AugmentedActionContext): Promise<IMenuResponse>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.GET_MENU]({ commit }) {
    return new Promise((resolve, reject) => {
      store.commit(MutationTypes.SET_LOADING, true);
      axios.get("/menu").then((response) => {
        const apiResponse: IMenuResponse = response.data;
        store.commit(MutationTypes.SET_MENU, apiResponse.data.menus);
        store.commit(MutationTypes.SET_LOADING, false);
        resolve(response.data as IMenuResponse);
      });
    });
  },
};
