import { GetterTree } from "vuex";
import { RootState } from "@/store";
import { State } from "./state";

export type Getters = {
  getThreatNone(state: State): unknown; // no getter for threat for now, may be it's a later thing
};

export const getters: GetterTree<State, RootState> & Getters = {
  getThreatNone: (state) => state.none,
};
