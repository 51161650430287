<template>
  <div class="p-6 bg-white cursor-wait m-4">
    <p class="mb-4 animate-pulse text-base text-neutral-700 dark:text-white">
      <span
        class="inline-block min-h-[1em] w-7/12 flex-auto bg-hover align-middle opacity-50"
      ></span>
      <span
        class="inline-block min-h-[1em] w-4/12 flex-auto bg-hover align-middle opacity-50"
      ></span>
      <span
        class="inline-block min-h-[1em] w-4/12 flex-auto bg-hover align-middle opacity-50"
      ></span>
      <span
        class="inline-block min-h-[1em] w-6/12 flex-auto bg-hover align-middle opacity-50"
      ></span>
      <span
        class="inline-block min-h-[1em] w-8/12 flex-auto bg-hover align-middle opacity-50"
      ></span>
    </p>
  </div>
</template>

<script>
export default {
  name: "LoadingCard",
};
</script>

<style scoped></style>
