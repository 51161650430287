import { MutationTree } from "vuex";

import { State } from "./state";
import { MutationTypes } from "./mutation.types";
import { IUser } from "@/interfaces/IUser";
import { IRole } from "@/interfaces/IRole";

export type Mutations<S = State> = {
  [MutationTypes.SET_ME](state: S, payload: IUser): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_ME](state: State, payload: IUser) {
    state.consultant_id = payload.consultant_id;
    state.updated_at = payload.updated_at;
    state.created_at = payload.created_at;
    state.id = payload.id;
    state.roles = payload.roles;
    state.introduced = payload.introduced;
    state.is_verified = payload.is_verified;
    state.first_name = payload.first_name;
    state.last_name = payload.last_name;
    state.email = payload.email;
    state.avatar = payload.avatar;
    state.avatar_url = payload.avatar_url;
    state.designation = payload.designation;
    state.designation_id = payload.designation_id;
    state.gender = payload.gender;
    state.locale = payload.locale;
  },
};
