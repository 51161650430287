import { ActionTree, ActionContext } from "vuex";
import { RootState } from "@/store";
import { State } from "./state";
import { Mutations } from "./mutations";
import { ActionTypes } from "./action.types";
import axios from "axios";
import dataService from "@/services/dataService";
import { IAnalysisQuestionForm } from "@/interfaces/analysisQuestion/IAnalysisQuestionForm";
import {
  ISaveQuestionErrorResponse,
  ISaveQuestionSuccessResponse,
} from "@/interfaces/analysisQuestion/ISaveQuestionResponse";
import { IAnalysisQuestion } from "@/interfaces/analysisQuestion/IAnalysisQuestion";
import { ISortRequest } from "@/interfaces/analysisQuestion/ISortRequest";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [ActionTypes.CREATE_QUESTION](
    { commit }: AugmentedActionContext,
    payload: IAnalysisQuestionForm
  ): Promise<ISaveQuestionSuccessResponse | ISaveQuestionErrorResponse>;

  [ActionTypes.GET_QUESTION](
    { commit }: AugmentedActionContext,
    questionID: number
  ): Promise<IAnalysisQuestion | false>;

  [ActionTypes.GET_QUESTIONS](
    { commit }: AugmentedActionContext,
    threatID: number
  ): Promise<Array<IAnalysisQuestion> | false>;

  [ActionTypes.GET_ANALYSIS_QUESTION](
    { commit }: AugmentedActionContext,
    payload: {
      companyID: number;
      questionID: number;
    }
  ): Promise<IAnalysisQuestion | false>;

  [ActionTypes.DELETE_QUESTION](
    { commit }: AugmentedActionContext,
    threatID: number
  ): Promise<boolean>;

  [ActionTypes.UPDATE_QUESTION](
    { commit }: AugmentedActionContext,
    payload: IAnalysisQuestionForm
  ): Promise<ISaveQuestionSuccessResponse | ISaveQuestionErrorResponse>;

  [ActionTypes.SORT_QUESTIONS](
    { commit }: AugmentedActionContext,
    payload: ISortRequest
  ): Promise<boolean>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.CREATE_QUESTION](
    { commit },
    payload: IAnalysisQuestionForm
  ) {
    return new Promise((resolve, reject) => {
      axios
        .post("/analysis-question", dataService.jsonToFormData(payload))
        .then((response) => {
          resolve(response.data as ISaveQuestionSuccessResponse);
        })
        .catch((error) => {
          resolve(error.response.data as ISaveQuestionErrorResponse);
        });
    });
  },
  async [ActionTypes.UPDATE_QUESTION](
    { commit },
    payload: IAnalysisQuestionForm
  ) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          "/analysis-question?_method=PUT",
          dataService.jsonToFormData(payload)
        )
        .then((response) => {
          resolve(response.data as ISaveQuestionSuccessResponse);
        })
        .catch((error) => {
          resolve(error.response.data as ISaveQuestionSuccessResponse);
        });
    });
  },
  async [ActionTypes.SORT_QUESTIONS]({ commit }, payload: ISortRequest) {
    return new Promise((resolve, reject) => {
      axios
        .post("/analysis-question/sort?_method=PUT", payload)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          resolve(error.response.data.data);
        });
    });
  },
  async [ActionTypes.GET_QUESTION]({ commit }, questionID: number) {
    return new Promise((resolve, reject) => {
      axios
        .get("/analysis-question/" + questionID)
        .then((response) => {
          resolve(response.data.data as IAnalysisQuestion);
        })
        .catch((error) => {
          console.error(error.response.data);
          resolve(false);
        });
    });
  },

  async [ActionTypes.GET_ANALYSIS_QUESTION](
    { commit },
    payload: {
      companyID: number;
      questionID: number;
    }
  ) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/company/${payload.companyID}/question/${payload.questionID}`)
        .then((response) => {
          resolve(response.data.data as IAnalysisQuestion);
        })
        .catch((error) => {
          console.error(error.response.data);
          resolve(false);
        });
    });
  },
  async [ActionTypes.DELETE_QUESTION]({ commit }, questionID: number) {
    return new Promise((resolve, reject) => {
      axios
        .delete("/analysis-question/" + questionID)
        .then((response) => {
          resolve(response.data.data as boolean);
        })
        .catch((error) => {
          console.error(error.response.data);
          resolve(false);
        });
    });
  },
  async [ActionTypes.GET_QUESTIONS]({ commit }, threatID: number) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/threat/${threatID}/analysis-question`)
        .then((response) => {
          resolve(response.data.data as Array<IAnalysisQuestion>);
        })
        .catch((error) => {
          console.error(error.response.data);
          resolve(false);
        });
    });
  },
};
