import { MutationTree } from "vuex";

import { State } from "./state";
import { MutationTypes } from "./mutation.types";

export type Mutations<S = State> = {
  [MutationTypes.NONE_RECOMMENDATION](state: S, payload: any): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.NONE_RECOMMENDATION](state: State, payload: any) {
    state.none = payload;
  },
};
