import { ActionTree, ActionContext } from "vuex";
import { RootState } from "@/store";
import { State } from "./state";
import { Mutations } from "./mutations";
import { ActionTypes } from "./action.types";
import axios from "axios";
import { ISortRequest } from "@/interfaces/analysisQuestion/ISortRequest";
import { IAnswer } from "@/interfaces/answers/IAnswer";
import { IAnswerSavePayload } from "@/interfaces/answers/IAnswerSavePayload";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [ActionTypes.SAVE_ANSWERS](
    { commit }: AugmentedActionContext,
    payload: { analysis_question: number; answers: Array<IAnswerSavePayload> }
  ): Promise<Array<IAnswer>>;

  [ActionTypes.GET_ANSWERS](
    { commit }: AugmentedActionContext,
    questionID: number
  ): Promise<Array<IAnswer> | false>;

  // [ActionTypes.SORT_ANSWERS](
  //   { commit }: AugmentedActionContext,
  //   payload: ISortRequest
  // ): Promise<boolean>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.SAVE_ANSWERS](
    { commit },
    payload: { analysis_question: number; answers: Array<IAnswerSavePayload> }
  ) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      payload.answers.forEach((obj, index) => {
        Object.entries(obj).forEach(([key, value]) => {
          if (value === true) value = 1; //laravel take string true as invalid and form data only sends string type
          if (value === false) value = 0; //laravel take string true as invalid and form data only sends string type
          if (value === null || value === undefined) return; //don't pass null or undefined
          if ("" !== value) formData.append(`answer[${index}][${key}]`, value);
        });
      });
      axios
        .post(
          `/analysis-question/${payload.analysis_question}/answers`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          resolve(response.data.data as Array<IAnswer>);
        })
        .catch((error) => {
          resolve(error.response.data);
        });
    });
  },

  // async [ActionTypes.SORT_ANSWERS]({ commit }, payload: ISortRequest) {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .post("/analysis-question/sort?_method=PUT", payload)
  //       .then((response) => {
  //         resolve(response.data.data);
  //       })
  //       .catch((error) => {
  //         resolve(error.response.data.data);
  //       });
  //   });
  // },

  async [ActionTypes.GET_ANSWERS]({ commit }, questionID: number) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/analysis-question/${questionID}/answers`)
        .then((response) => {
          resolve(response.data.data as Array<IAnswer>);
        })
        .catch((error) => {
          console.error(error.response.data);
          resolve(false);
        });
    });
  },
};
