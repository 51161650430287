import { GetterTree } from "vuex";
import { RootState } from "@/store";
import { State } from "./state";
import { IState } from "@/interfaces/menu/IState";

export type Getters = {
  getMenu(state: State): IState;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getMenu: (state) => state,
};
