import { GetterTree } from "vuex";
import { RootState } from "@/store";
import { State } from "./state";
import { ISubscription } from "@/interfaces/subscription/ISubscription";

export type Getters = {
  getSubscriptions(state: State): Array<ISubscription>;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getSubscriptions: (state) => {
    return state.subscriptions;
  },
};
