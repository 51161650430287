export default new (class {
  jsonToFormData(payload: object): FormData {
    const formData = new FormData();
    Object.entries(payload).map(([key, value]) => {
      if (value === true) value = 1; //laravel take string true as invalid and form data only sends string type
      if (value === false) value = 0; //laravel take string true as invalid and form data only sends string type
      if (value === null || value === undefined) return; //don't pass null or undefined
      /**
       * handling array type of values
       */
      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          /**
           * @important:
           * IF value is an array again, we provide it as string csv
           * We don't support multidimensional array for form request.
           * For question conditions, we're handling array of answer ids as csv
           */
          if (value[i] === true) value[i] = 1; //laravel take string true as invalid and form data only sends string type
          if (value[i] === false) value[i] = 0; //laravel take string true as invalid and form data only sends string type
          if (value[i] === null || value[i] === undefined) continue; //don't pass null or undefined
          formData.append(key + "[]", value[i]);
        }
        return;
      }
      if ("" !== value) formData.append(key, value);
    });
    return formData;
  }
})();
