import { IUser } from "@/interfaces/IUser";

export type State = IUser;

export const state: State = {
  email: null,
  consultant_id: null,
  updated_at: null,
  created_at: null,
  id: null,
  roles: [],
  introduced: false,
  is_verified: false,
  first_name: null,
  last_name: null,
  designation_id: null,
  designation: null,
  locale: "de",
  avatar: null,
  avatar_url: null,
  gender: null,
};
