import { Store as VuexStore, DispatchOptions, Module } from "vuex";

import { RootState } from "@/store";
import { state } from "./state";
import { actions, Actions } from "./actions";

import type { State } from "./state";

export { State };

export type DesignationStore<S = State> = Omit<VuexStore<S>, "dispatch"> & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload: Parameters<Actions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<Actions[K]>;
};

export const store: Module<State, RootState> = {
  state,
  actions,
};
