import { ActionTree, ActionContext } from "vuex";
import { RootState } from "@/store";
import { State } from "./state";
import { Mutations } from "./mutations";
import { ActionTypes } from "./action.types";
import axios from "axios";
import dataService from "@/services/dataService";
import { IRecommendationForm } from "@/interfaces/recommendation/IRecommendationForm";
import {
  ISaveRecommendationErrorResponse,
  ISaveRecommendationSuccessResponse,
} from "@/interfaces/recommendation/ISaveRecommendationResponse";
import { IRecommendation } from "@/interfaces/recommendation/IRecommendation";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [ActionTypes.CREATE_RECOMMENDATION](
    { commit }: AugmentedActionContext,
    payload: IRecommendationForm
  ): Promise<
    ISaveRecommendationSuccessResponse | ISaveRecommendationErrorResponse
  >;

  [ActionTypes.GET_RECOMMENDATION](
    { commit }: AugmentedActionContext,
    recommendationID: number
  ): Promise<IRecommendation | false>;

  [ActionTypes.DELETE_RECOMMENDATION](
    { commit }: AugmentedActionContext,
    recommendationID: number
  ): Promise<boolean>;

  [ActionTypes.GET_RECOMMENDATIONS](
    { commit }: AugmentedActionContext,
    threatID: number
  ): Promise<Array<IRecommendation> | false>;

  [ActionTypes.GET_RECOMMENDATIONS_PER_QUESTION](
    { commit }: AugmentedActionContext,
    questionID: number
  ): Promise<Array<IRecommendation> | false>;

  [ActionTypes.UPDATE_RECOMMENDATION](
    { commit }: AugmentedActionContext,
    payload: IRecommendationForm
  ): Promise<
    ISaveRecommendationSuccessResponse | ISaveRecommendationErrorResponse
  >;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.CREATE_RECOMMENDATION](
    { commit },
    payload: IRecommendationForm
  ) {
    return new Promise((resolve, reject) => {
      axios
        .post("/recommendation", dataService.jsonToFormData(payload))
        .then((response) => {
          resolve(response.data as ISaveRecommendationSuccessResponse);
        })
        .catch((error) => {
          resolve(error.response.data as ISaveRecommendationErrorResponse);
        });
    });
  },
  async [ActionTypes.UPDATE_RECOMMENDATION](
    { commit },
    payload: IRecommendationForm
  ) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          "/recommendation?_method=PUT",
          dataService.jsonToFormData(payload)
        )
        .then((response) => {
          resolve(response.data as ISaveRecommendationSuccessResponse);
        })
        .catch((error) => {
          resolve(error.response.data as ISaveRecommendationErrorResponse);
        });
    });
  },
  async [ActionTypes.GET_RECOMMENDATION]({ commit }, recommendationID: number) {
    return new Promise((resolve, reject) => {
      axios
        .get("/recommendation/" + recommendationID)
        .then((response) => {
          resolve(response.data.data as IRecommendation);
        })
        .catch((error) => {
          console.error(error.response.data);
          resolve(false);
        });
    });
  },
  async [ActionTypes.DELETE_RECOMMENDATION](
    { commit },
    recommendationID: number
  ) {
    return new Promise((resolve, reject) => {
      axios
        .delete("/recommendation/" + recommendationID)
        .then((response) => {
          resolve(response.data.data as boolean);
        })
        .catch((error) => {
          console.error(error.response.data);
          resolve(false);
        });
    });
  },
  async [ActionTypes.GET_RECOMMENDATIONS]({ commit }, threatID: number) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/threat/${threatID}/recommendation`)
        .then((response) => {
          resolve(response.data.data as Array<IRecommendation>);
        })
        .catch((error) => {
          console.error(error.response.data);
          resolve(false);
        });
    });
  },

  async [ActionTypes.GET_RECOMMENDATIONS_PER_QUESTION](
    { commit },
    questionID: number
  ) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/analysis-question/${questionID}/recommendation`)
        .then((response) => {
          resolve(response.data.data as Array<IRecommendation>);
        })
        .catch((error) => {
          console.error(error.response.data);
          resolve(false);
        });
    });
  },
};
