import { ActionTree, ActionContext } from "vuex";
import { RootState, store } from "@/store";
import { State } from "./state";
import { Mutations } from "./mutations";
import { ActionTypes } from "./action.types";
import axios from "axios";
import { MutationTypes } from "./mutation.types";
import { IGetSubscriptionResponse } from "@/interfaces/subscription/IGetSubscriptionResponse";
import { ISubscription } from "@/interfaces/subscription/ISubscription";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [ActionTypes.SYNC_SUBSCRIPTIONS]({
    commit,
  }: AugmentedActionContext): Promise<IGetSubscriptionResponse>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.SYNC_SUBSCRIPTIONS]({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get("/subscription").then((response) => {
        store.commit(
          MutationTypes.SET_SUBSCRIPTIONS,
          response.data.data as Array<ISubscription>
        );
        resolve(response.data as IGetSubscriptionResponse);
      });
    });
  },
};
