import { ActionTree, ActionContext } from "vuex";
import { RootState, store } from "@/store";
import { ActionTypes } from "./action.types";
import axios from "axios";
import { State } from "@/store/designation/state";
import {
  IErrorResponse,
  ISuccessResponse,
} from "@/interfaces/settingsView/ISuccessResponse";
import {
  IErrorResponse as IConsultantSettingsSaveErrorResponse,
  ISuccessResponse as IConsultantSettingsSaveSuccessResponse,
} from "@/interfaces/consultant/ISaveResponse";
import { Mutations } from "@/store/auth/mutations";
import { IProfileForm } from "@/interfaces/settingsView/IProfileForm";
import { IConsultantEditForm } from "@/interfaces/consultant/IConsultantEditForm";
import dataService from "@/services/dataService";
import { IConsultantSettings } from "@/interfaces/consultant/IConsultantSettings";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [ActionTypes.SET_USER_PROFILE](
    { commit }: AugmentedActionContext,
    payload: IProfileForm
  ): Promise<ISuccessResponse | IErrorResponse>;
  [ActionTypes.SAVE_CONSULTANT_SETTINGS](
    { commit }: AugmentedActionContext,
    payload: { id: number; data: IConsultantEditForm }
  ): Promise<
    | IConsultantSettingsSaveErrorResponse
    | IConsultantSettingsSaveSuccessResponse
  >;
  [ActionTypes.GET_CONSULTANT_SETTINGS](
    { commit }: AugmentedActionContext,
    id: number
  ): Promise<IConsultantSettings | false>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.SET_USER_PROFILE]({ commit }, payload: IProfileForm) {
    return new Promise((resolve) => {
      const formData = new FormData();
      formData.append("first_name", payload.first_name);
      formData.append("last_name", payload.last_name);
      formData.append("designation_id", payload.designation_id.toString());
      formData.append("introduced", payload.introduced.toString());
      formData.append("remove_avatar", payload.remove_avatar.toString());
      formData.append("gender", payload.gender);
      if (payload.avatar) formData.append("avatar", payload.avatar);
      axios
        .post("/profile", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => resolve(response.data as ISuccessResponse))
        .catch((error) => {
          const errorResponse = error.response.data as IErrorResponse;
          errorResponse.status = error.response.status;
          resolve(errorResponse);
        });
    });
  },
  async [ActionTypes.SAVE_CONSULTANT_SETTINGS](
    { commit },
    payload: { id: number; data: IConsultantEditForm }
  ) {
    return new Promise((resolve) => {
      axios
        .post(
          `/consultant/settings/${payload.id}`,
          dataService.jsonToFormData(payload.data),
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) =>
          resolve(response.data as IConsultantSettingsSaveSuccessResponse)
        )
        .catch((error) => {
          const errorResponse = error.response
            .data as IConsultantSettingsSaveErrorResponse;
          errorResponse.status = error.response.status;
          resolve(errorResponse);
        });
    });
  },
  async [ActionTypes.GET_CONSULTANT_SETTINGS]({ commit }, id: number) {
    return new Promise((resolve) => {
      axios
        .get(`/consultant/settings/${id}`)
        .then((response) => resolve(response.data.data as IConsultantSettings))
        .catch((error) => {
          console.error(error);
          resolve(false);
        });
    });
  },
};
