import { GetterTree } from "vuex";
import { RootState } from "@/store";
import { State } from "./state";
import { IUser } from "@/interfaces/IUser";

export type Getters = {
  getUser(state: State): IUser;
  getDefaultUserState(state: State): IUser;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getUser: (state) => state,
  getDefaultUserState: (state) => {
    return {
      email: null,
      consultant_id: null,
      updated_at: null,
      created_at: null,
      id: null,
      roles: [],
      introduced: false,
      is_verified: false,
      first_name: null,
      last_name: null,
      designation_id: null,
      designation: null,
      locale: "de",
      avatar: null,
      avatar_url: null,
      gender: null,
    };
  },
};
