import { GetterTree } from "vuex";
import { RootState } from "@/store";
import { State } from "./state";
import { ICompany } from "@/interfaces/company/ICompany";

export type Getters = {
  getQuestionNone(state: State): any; // no getter for threat for now, may be it's a later thing
};

export const getters: GetterTree<State, RootState> & Getters = {
  getQuestionNone: (state) => state.none,
};
