import { ActionTree, ActionContext } from "vuex";

import { RootState } from "@/store";

import { State } from "./state";
import { Mutations } from "./mutations";
import { ActionTypes } from "./action.types";
import ILoginForm from "@/interfaces/auth/ILoginForm";
import IRegisterForm from "@/interfaces/auth/IRegisterForm";
import axios from "axios";
import {
  ISuccessResponse as IRegisterSuccessResponse,
  IErrorResponse as IRegisterErrorResponse,
} from "@/interfaces/auth/IAuthResponse";
import { ISuccessResponse as IGetConsulantSuccessResponse } from "@/store/domain/interfaces/IConsulantResponse";
import IGetConsultantRequest from "@/interfaces/auth/IGetConsultantRequest";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [ActionTypes.GET_CONSULTANT](
    { commit }: AugmentedActionContext,
    payload: IGetConsultantRequest
  ): Promise<IGetConsulantSuccessResponse | null>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.GET_CONSULTANT](
    { commit },
    payload: IGetConsultantRequest
  ) {
    return new Promise((resolve) => {
      axios
        .get("/getConsultant", {
          params: payload,
        })
        .then((response) =>
          resolve(response.data as IGetConsulantSuccessResponse)
        );
    });
  },
};
