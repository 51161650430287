<template>
  <div class="bg-white py-20">
    <img
      class="mx-auto"
      src="/img/recommendation/no-recommendation.png"
      alt="no-recommendation"
    />
    <p class="text-center text-primary">
      {{ $t("employee.recommendationView.archive.no_recommendation_message") }}
    </p>
    <p class="text-center text-primary">
      {{ $t("employee.recommendationView.archive.potential_reason_message") }}
    </p>
    <p class="text-center text-primary">
      <router-link to="/company/threats">{{
        $t("employee.recommendationView.archive.click_here")
      }}</router-link>
      {{ $t("employee.recommendationView.archive.to_see_threats") }}
    </p>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped></style>
