import { IConsultantSettings } from "@/interfaces/consultant/IConsultantSettings";

export type State = {
  consultant_settings: IConsultantSettings;
};
export const state: State = {
  consultant_settings: {
    mail_from_address: "info@securious.de",
    mail_from_name: "Securious",
    company_name: "",
    consultant: null,
    consultant_id: 1, //default consultant is admin
    font: "",
    font_url: "",
    host_name: "",
    logo: "",
    link_color: "#2592d1",
    logo_url: "/img/securious-logo.svg",
    primary_color: "#055ca8",
    secondary_color: "#2592d1",
    tertiary_color: "#6c757d",
    data_protection_url: process.env.VUE_APP_DEFAULT_IMPRINT_URL,
    imprint_url: process.env.VUE_APP_DEFAULT_DATA_PROTECTION_URL,
  },
};
