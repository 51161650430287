import { GetterTree } from "vuex";
import { RootState } from "@/store";
import { State } from "./state";
import { IIndustry } from "@/interfaces/industryView/IIndustry";

export type Getters = {
  getIndustries(state: State): Array<IIndustry>;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getIndustries: (state) => state.industries,
};
