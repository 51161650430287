// https://codesandbox.io/s/using-vuex-4-modules-in-vue-3-with-typescript-7ygvy?file=/src/main.ts:0-33
import { createStore } from "vuex";

import { store as auth, AuthStore, State as AuthState } from "@/store/auth";

import {
  store as domain,
  DomainStore,
  State as DomainState,
} from "@/store/domain";

import {
  store as designation,
  DesignationStore,
  State as DesignationState,
} from "@/store/designation";

import {
  store as priority,
  PriorityStore,
  State as PriorityState,
} from "@/store/priority";

import {
  store as settings,
  SettingStore,
  State as SettingState,
} from "@/store/settings";

import {
  store as company,
  CompanyStore,
  State as CompanyState,
} from "@/store/company";

import {
  store as subscription,
  SubscriptionStore,
  State as SubscriptionState,
} from "@/store/subscription";

import {
  store as industry,
  IndustryStore,
  State as IndustryState,
} from "@/store/industry";

import {
  store as threat,
  ThreatStore,
  State as ThreatState,
} from "@/store/threat";

import {
  store as question,
  QuestionStore,
  State as QuestionState,
} from "@/store/analysisQuestion";

import {
  store as answer,
  AnswerStore,
  State as AnswerState,
} from "@/store/answer";

import {
  store as recommendation,
  RecommendationStore,
  State as RecommendationState,
} from "@/store/recommendation";

import { store as menu, MenuStore, State as MenuState } from "@/store/menu";

export type RootState = {
  auth: AuthState;
  domain: DomainState;
  designation: DesignationState;
  priority: PriorityState;
  settings: SettingState;
  company: CompanyState;
  menu: MenuState;
  industry: IndustryState;
  subscription: SubscriptionState;
  threat: ThreatState;
  question: QuestionState;
  answer: AnswerState;
  recommendation: RecommendationState;
};

export type Store = AuthStore<Pick<RootState, "auth">> &
  DomainStore<Pick<RootState, "domain">> &
  DesignationStore<Pick<RootState, "designation">> &
  PriorityStore<Pick<RootState, "priority">> &
  SettingStore<Pick<RootState, "settings">> &
  CompanyStore<Pick<RootState, "company">> &
  MenuStore<Pick<RootState, "menu">> &
  IndustryStore<Pick<RootState, "industry">> &
  SubscriptionStore<Pick<RootState, "subscription">> &
  QuestionStore<Pick<RootState, "question">> &
  ThreatStore<Pick<RootState, "threat">> &
  AnswerStore<Pick<RootState, "answer">> &
  RecommendationStore<Pick<RootState, "recommendation">>;

export const store = createStore({
  modules: {
    auth: auth,
    domain: domain,
    designation: designation,
    settings: settings,
    company: company,
    menu: menu,
    priority: priority,
    industry: industry,
    subscription: subscription,
    threat: threat,
    question: question,
    answer: answer,
    recommendation: recommendation,
  },
});

export function useStore(): Store {
  return store as Store;
}
