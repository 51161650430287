export const externalTooltipHandler = (context: {
  chart: any;
  tooltip: any;
}) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map((b: { lines: any }) => {
      return b.lines;
    });

    const tableHead = document.createElement("thead");

    titleLines.forEach((title: string) => {
      const tr = document.createElement("tr");
      tr.style.borderWidth = String(0);

      const th = document.createElement("th");
      th.style.borderWidth = String(0);
      const text = document.createTextNode(title);

      th.appendChild(text);
      tr.appendChild(th);
      tableHead.appendChild(tr);
    });

    const tableBody = document.createElement("tbody");
    bodyLines.forEach((body: string, i: string | number) => {
      const colors = tooltip.labelColors[i];

      const span = document.createElement("span");
      span.style.background = colors.backgroundColor;
      span.style.borderColor = colors.borderColor;
      span.style.borderWidth = "2px";
      span.style.marginRight = "10px";
      span.style.height = "10px";
      span.style.width = "10px";
      span.style.display = "inline-block";

      const tr = document.createElement("tr");
      tr.style.backgroundColor = "inherit";
      tr.style.borderWidth = String(0);

      const td = document.createElement("td");
      td.style.borderWidth = String(0);

      const text = document.createTextNode(body);

      td.appendChild(span);
      td.appendChild(text);
      tr.appendChild(td);
      tableBody.appendChild(tr);
    });

    /**
     * creating footer
     */
    const tableFooter = document.createElement("tfoot");
    const td = document.createElement("td");
    const tr = document.createElement("tr");
    td.style.borderWidth = String(0);

    const activePoint = context.chart.tooltip._active[0];
    const riskScoreValue =
      context.chart.data.datasets[1].data[activePoint.index];
    const securityScoreValue =
      context.chart.data.datasets[0].data[activePoint.index];
    const diff = Math.abs(securityScoreValue - riskScoreValue);
    /**
     * gap
     */
    const gapContainer = document.createElement("div");
    gapContainer.innerHTML = `<p>Gap <span class="text-danger">${diff} Points</span></p>`;
    gapContainer.style.textAlign = "center";
    gapContainer.style.fontWeight = "bold";
    gapContainer.style.marginTop = "5px";
    gapContainer.style.marginBottom = "5px";
    // gapContainer.appendChild(gap);
    // gapContainer.appendChild(diff_text);
    // gapContainer.appendChild(points);
    td.appendChild(gapContainer);
    tr.appendChild(td);
    /**
     * notice
     */
    const noticeCompleted =
      "Bitte schließen Sie den Gap, um sicherer zu werden.";
    const noticeInComplete =
      "Sie haben den Gap erfolgreich geschlossen. Überprüfen Sie bitte regelmäßig, ob offene Bedrohungen vorhanden sind, da diese den Risiko-Score verändern können.";
    const noticeContainer = document.createElement("div");
    noticeContainer.style.textAlign = "center";
    if (diff <= 0) {
      noticeContainer.innerHTML = `<p>${noticeCompleted}</p>`;
    } else {
      noticeContainer.innerHTML = `<p>${noticeInComplete}</p>`;
    }
    td.appendChild(noticeContainer);
    tr.appendChild(td);
    tableBody.appendChild(tr);

    const tableRoot = tooltipEl.querySelector("table");

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children`
    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
    tableRoot.appendChild(tableFooter);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = positionX + tooltip.caretX + "px";
  tooltipEl.style.top = positionY + tooltip.caretY + "px";
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding =
    tooltip.options.padding + "px " + tooltip.options.padding + "px";
};

const getOrCreateTooltip = (chart: {
  canvas: {
    parentNode: {
      querySelector: (arg0: string) => any;
      appendChild: (arg0: any) => void;
    };
  };
}) => {
  let tooltipEl = chart.canvas.parentNode.querySelector("div");

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.style.background = "rgba(255, 255, 255, 0.9)";
    tooltipEl.style.borderRadius = "3px";
    tooltipEl.style.color = "black";
    tooltipEl.style.width = "150px";
    tooltipEl.style.fontSize = "12px";
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.transform = "translate(-50%, 0)";
    tooltipEl.style.transition = "all .1s ease";
    // tooltipEl.class = "shadow";
    // Apply styling to the div
    tooltipEl.style.boxShadow = "0px 0px 10px rgba(0, 0, 0, 0.1)";

    const table = document.createElement("table");
    table.style.margin = "0px";

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};
