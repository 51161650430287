// index.js
import en from "./locales/en.json";
import de from "./locales/de.json";
import { createI18n } from "vue-i18n";

const defaultLocale = process.env.VUE_APP_DEFAULT_LOCALE;

const languages = {
  en: en,
  de: de,
};

const messages = Object.assign(languages);

export default createI18n({
  locale: defaultLocale,
  fallbackLocale: "en",
  messages,
  legacy: false,
});
