import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import { store } from "./store";
import i18n from "@/i18n";
import "./assets/css/index.scss";
import "@fortawesome/fontawesome-free/css/all.css";
import "tw-elements";
import "sweetalert2/dist/sweetalert2.min.css";
import VueSelect from "vue-select";
import Editor from "@tinymce/tinymce-vue";
import VueSimpleRangeSlider from "vue-simple-range-slider";
import "vue-simple-range-slider/css";
import { Form, ErrorMessage, Field } from "vee-validate";
import { Sortable } from "sortablejs-vue3";
import VOtpInput from "vue3-otp-input";
import axios, { InternalAxiosRequestConfig } from "axios";
import authService from "@/services/authService";
import VideoPlayer from "@/components/VideoPlayer.vue";
import RadioButton from "@/components/company/riskAnalysis/radioButton.vue";
import DropDown from "@/components/company/riskAnalysis/dropDown.vue";
import MultipleChoice from "@/components/company/riskAnalysis/multipleChoice.vue";
import MultiSelectDropDown from "@/components/company/riskAnalysis/multiSelectDropDown.vue";
import rangeSelect from "@/components/company/riskAnalysis/rangeSelect.vue";
import "vue3-colorpicker/style.css";
import Vue3ColorPicker from "vue3-colorpicker";
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

// Interceptor
axios.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  // Add authentication header to request
  if (authService.isLoggedIn()) {
    /**
     * all the requests now should be going with auth header
     */
    config.headers.Authorization = "Bearer " + authService.getToken();
  }
  return config;
});
createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .component("radio-button", RadioButton)
  .component("drop-down", DropDown)
  .component("multiple-choice", MultipleChoice)
  .component("multi-select-drop-down", MultiSelectDropDown)
  .component("range-select", rangeSelect)
  .component("range-slider", VueSimpleRangeSlider)
  .component("video-player", VideoPlayer)
  .component("v-select", VueSelect)
  .component("sortable", Sortable)
  .component("Form", Form)
  .component("ErrorMessage", ErrorMessage)
  .component("Field", Field)
  .component("editor", Editor)
  .component("otp-input", VOtpInput)
  .mount("#app");
