import { ActionTree, ActionContext } from "vuex";
import { RootState } from "@/store";
import { State } from "./state";
import { Mutations } from "./mutations";
import { ActionTypes } from "./action.types";
import axios from "axios";
import {
  ISaveThreatSuccessResponse,
  ISaveThreatErrorResponse,
} from "@/interfaces/threat/ISaveThreatResponse";
import { IThreatForm } from "@/interfaces/threat/IThreatForm";
import dataService from "@/services/dataService";
import { IThreat } from "@/interfaces/threat/IThreat";
import { IQuestionAnswers } from "@/interfaces/threat/IQuestionAnswers";
import { IDuplicateResponseData } from "@/interfaces/threat/IDuplicateResponseData";
import { IRiskAnalysis } from "@/interfaces/riskAnalysis/IRiskAnalysis";
import { IAnalysisQuestion } from "@/interfaces/analysisQuestion/IAnalysisQuestion";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [ActionTypes.CREATE_THREAT](
    { commit }: AugmentedActionContext,
    payload: IThreatForm
  ): Promise<ISaveThreatSuccessResponse | ISaveThreatErrorResponse>;

  [ActionTypes.GET_THREAT](
    { commit }: AugmentedActionContext,
    payload: number
  ): Promise<IThreat | false>;

  [ActionTypes.GET_COMPANY_THREAT](
    { commit }: AugmentedActionContext,
    payload: { companyID: number; threatID: number }
  ): Promise<IRiskAnalysis | false>;

  [ActionTypes.GET_THREATS]({
    commit,
  }: AugmentedActionContext): Promise<Array<IThreat> | false>;

  [ActionTypes.GET_TEMPLATE]({
    commit,
  }: AugmentedActionContext): Promise<Array<IThreat> | false>;

  [ActionTypes.GET_QUESTIONS_ANSWERS](
    { commit }: AugmentedActionContext,
    id: number
  ): Promise<Array<IQuestionAnswers> | false>;

  [ActionTypes.UPDATE_THREAT](
    { commit }: AugmentedActionContext,
    payload: IThreatForm
  ): Promise<ISaveThreatSuccessResponse | ISaveThreatErrorResponse>;

  [ActionTypes.DUPLICATE](
    { commit }: AugmentedActionContext,
    threatID: number
  ): Promise<IDuplicateResponseData | false>;

  [ActionTypes.ACTIVATE](
    { commit }: AugmentedActionContext,
    payload: number
  ): Promise<boolean>;

  [ActionTypes.DEACTIVATE](
    { commit }: AugmentedActionContext,
    payload: number
  ): Promise<boolean>;

  [ActionTypes.DELETE_THREAT](
    { commit }: AugmentedActionContext,
    payload: number
  ): Promise<boolean>;

  /**
   * employee specific actions
   */
  [ActionTypes.GET_COMPANY_THREATS](
    { commit }: AugmentedActionContext,
    company_id: number
  ): Promise<Array<IThreat> | false>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.CREATE_THREAT]({ commit }, payload: IThreatForm) {
    return new Promise((resolve, reject) => {
      axios
        .post("/threat", dataService.jsonToFormData(payload), {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          resolve(response.data as ISaveThreatSuccessResponse);
        })
        .catch((error) => {
          resolve(error.response.data as ISaveThreatErrorResponse);
        });
    });
  },
  async [ActionTypes.UPDATE_THREAT]({ commit }, payload: IThreatForm) {
    return new Promise((resolve, reject) => {
      axios
        .post("/threat?_method=PUT", dataService.jsonToFormData(payload), {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          resolve(response.data as ISaveThreatSuccessResponse);
        })
        .catch((error) => {
          resolve(error.response.data as ISaveThreatSuccessResponse);
        });
    });
  },
  async [ActionTypes.DUPLICATE]({ commit }, threatID: number) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/threat/${threatID}/duplicate?_method=PUT`)
        .then((response) => {
          resolve(response.data.data as IDuplicateResponseData);
        })
        .catch((error) => {
          console.error(error);
          resolve(false);
        });
    });
  },
  async [ActionTypes.ACTIVATE]({ commit }, payload: number) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/threat/${payload}/activate?_method=PUT`)
        .then((response) => {
          resolve(response.data.data as boolean);
        })
        .catch((error) => {
          console.error(error.response.data);
          resolve(false);
        });
    });
  },
  async [ActionTypes.DEACTIVATE]({ commit }, payload: number) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/threat/${payload}/deactivate?_method=PUT`)
        .then((response) => {
          resolve(response.data.data as boolean);
        })
        .catch((error) => {
          resolve(error.response.data.data as boolean);
        });
    });
  },
  async [ActionTypes.GET_THREAT]({ commit }, payload: number) {
    return new Promise((resolve, reject) => {
      axios
        .get("/threat/" + payload)
        .then((response) => {
          resolve(response.data.data as IThreat);
        })
        .catch((error) => {
          console.error(error.response.data);
          resolve(false);
        });
    });
  },
  async [ActionTypes.GET_COMPANY_THREAT](
    { commit },
    payload: { companyID: number; threatID: number }
  ) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/company/${payload.companyID}/threat/${payload.threatID}`)
        .then((response) => {
          resolve(response.data.data as IRiskAnalysis);
        })
        .catch((error) => {
          console.error(error.response.data);
          resolve(false);
        });
    });
  },
  async [ActionTypes.GET_QUESTIONS_ANSWERS]({ commit }, id: number) {
    return new Promise((resolve, reject) => {
      axios
        .get(`threat/${id}/questions-answers/`)
        .then((response) => {
          resolve(response.data.data as Array<IQuestionAnswers>);
        })
        .catch((error) => {
          console.error(error.response.data);
          resolve(false);
        });
    });
  },
  async [ActionTypes.GET_THREATS]({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/threat")
        .then((response) => {
          resolve(response.data.data as Array<IThreat>);
        })
        .catch((error) => {
          console.error(error.response.data);
          resolve(false);
        });
    });
  },
  async [ActionTypes.GET_TEMPLATE]({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/template")
        .then((response) => {
          resolve(response.data.data as Array<IThreat>);
        })
        .catch((error) => {
          console.error(error.response.data);
          resolve(false);
        });
    });
  },
  async [ActionTypes.DELETE_THREAT]({ commit }, payload: number) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/threat/${payload}`)
        .then((response) => {
          resolve(response.data.data as boolean);
        })
        .catch((error) => {
          console.error(error.response.data);
          resolve(false);
        });
    });
  },

  /**
   * employee specific actions
   */

  async [ActionTypes.GET_COMPANY_THREATS]({ commit }, company_id: number) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/company/${company_id}/threats`)
        .then((response) => {
          resolve(response.data.data as Array<IThreat>);
        })
        .catch((error) => {
          console.error(error.response.data);
          resolve(false);
        });
    });
  },
};
