import { ActionTree, ActionContext } from "vuex";
import { RootState, store } from "@/store";
import { ActionTypes } from "./action.types";
import axios from "axios";
import { State } from "./state";
import { IIndustryResponse } from "@/interfaces/industryView/IIndustryResponse";
import { IIndustrySaveResponse } from "@/interfaces/industryView/IIndustrySaveResponse";
import { IIndustryForm } from "@/interfaces/industryView/IIndustryForm";
import { Mutations } from "./mutations";
import { IIndustry } from "@/interfaces/industryView/IIndustry";
import { MutationTypes } from "@/store/industry/mutation.types";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface Actions {
  [ActionTypes.SYNC_INDUSTRY]({
    commit,
  }: AugmentedActionContext): Promise<IIndustryResponse>;

  [ActionTypes.SAVE_INDUSTRY](
    { commit }: AugmentedActionContext,
    payload: IIndustryForm
  ): Promise<IIndustrySaveResponse>;

  [ActionTypes.DELETE_INDUSTRY](
    { commit }: AugmentedActionContext,
    payload: IIndustry
  ): Promise<boolean>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.SYNC_INDUSTRY]({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get("/industry").then((response) => {
        const industryResponse = response.data as IIndustryResponse;
        store.commit(MutationTypes.SET_INDUSTRY, industryResponse.data);
        resolve(response.data as IIndustryResponse);
      });
    });
  },
  async [ActionTypes.SAVE_INDUSTRY]({ commit }, payload: IIndustryForm) {
    return new Promise((resolve, reject) => {
      axios.post("/industry", payload).then(async (response) => {
        await store.dispatch(ActionTypes.SYNC_INDUSTRY); //sync post save
        resolve(response.data as IIndustrySaveResponse);
      });
    });
  },

  async [ActionTypes.DELETE_INDUSTRY]({ commit }, payload: IIndustry) {
    return new Promise((resolve) => {
      axios
        .delete(`/industry/${payload.id}`)
        .then(async (response) => {
          await store.dispatch(ActionTypes.SYNC_INDUSTRY); //sync post delete
          resolve(response.data.data.isDeleted as boolean);
        })
        .catch((error) => {
          console.error(error);
          resolve(false);
        });
    });
  },
};
